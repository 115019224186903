import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Cancel';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/NoteAdd';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Settings } from '../settings.js';
import { validateToken } from '../functions.js';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {useDropzone} from 'react-dropzone'

const backend = Settings.backendPath;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    "& .MuiGridList-root":{
      marginLeft: '20px !important',
      marginRight: '20px !important',
      marginTop: '10px !important',
      marginBottom: '10px !important'
    },
    "& .MuiGridListTile-root":{
        height: '180px !important',
        maxWidth: '220px'
    },

    "& .MuiGridListTileBar-root":{
      backgroundColor: 'transparent'
    },
    "& .MuiGridListTileBar-title" : {
        fontSize: '0.60rem',
        fontWeight: 'bold',
        color: 'black',
        whiteSpace: 'normal',
        lineHeight: '10px',
        marginBottom: '3px'
    },
    "& .MuiGridListTileBar-subtitle" : {
        fontSize: '0.55rem',
        marginBottom: 8,
        color: "cf5978"
    },
    "& .MuiGridListTileBar-rootSubtitle":{
      height: 30
    },
    "& .MuiTypography-root" :{
        width: 'calc(100%)',
        marginLeft: 22,
        marginRight: 5,
        marginTop: 20,
        fontWeight: 'bold',
        fontSize: 19,
        backgroundColor: 'white',
        color: '#88929C',
        height: 34,
    },
    "& MuiBreadcrumbs-ol": {
        boxShadow: '2px 2px 2px #A0A0A0'
    },

    "&  .MuiGridListTileBar-titleWrap": {
      color: 'grey',
      marginLeft: '0px'
  },

    "&  .MuiIconButton-root": {
      color: '#88929C !important',
      marginBottom: '210px',
      marginRight: '20px',
      width: '2px',
      height: '2px'
    },

    "& .MuiFab-primary":{
        backgroundColor: '#88929C',
        color: '#F4F4F4'
    },
    "& .MuiGridListTile-imgFullWidth":{
        width: '80%',
        marginLeft: 10,
        height: '95%'
    },
    "& .MuiAlert-outlinedInfo" :{
      backgroundColor: 'white',
    },
    "& .MuiAlert-outlinedSuccess" :{
      backgroundColor: 'white',
    },
    "& .MuiAlert-outlinedError" :{
      backgroundColor: 'white',
    },
  },
  gridList: {
    width: '100%',
    height: '100%',
    minHeight: '400px'
  },
  gridActive: {
    width: '100%',
    height: '100%',    
    borderRadius: '30px',
    minHeight: '400px',
  //  border: '1px solid grey',
    backgroundColor: '#F0F0F0'
  },
  icon: {
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#F4F4F4'
  },
  buttonFile:{
    right: 50,
    bottom: 50,
    position: 'fixed'
  },
  buttonFolder:{
    right: 140,
    bottom: 50,
    position: 'fixed',
    backgroundColor: '#F4F4F4'
  },
  fabIcon: {
      fontSize:'2.5rem'
  },
  typeData: {
      marginTop: 10,
      marginBottom: 10
  },
  switch: {
    marginTop: 20,
    marginBottom: 20
  },
  image: {
      marginLeft: 0,
      opacity: 0.9,
      height: 'calc(100% - 40px)',
      width: '100px'
  },
  dialog: {
    margin: '20px',
    backgroundColor: 'white'
  },
  bread: {
    marginTop: '15px',
    marginLeft: '5px'
  },
  dialog: {
    margin: '20px',
    backgroundColor: 'white'
    },
  
    contentResponse:{
      margin: '20px',
      backgroundColor: 'white',
      padding: '15px',
      border: '1px solid #C6C9CD',
      minHeight: '22px',
      borderRadius: '5px'
    },
  
    label: {
      marginTop: '8px',
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#9aa0a8'
    },
    area: {
      marginTop: '8px',
      fontSize: '13px',
      border: '1px solid lightgray',
      borderRadius: '5px'
    },
    labelRoot: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#9aa0a8'
    },
    dialogTitle:{
      //backgroundColor: '#9aa0a8',
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#9aa0a8',
      borderBottom: '2px solid #88929C'
    },
    dialogContent:{
     backgroundColor: 'white',
    },
    switch:{
      marginLeft: '10px',
     },
    dialogActions:{
      backgroundColor: 'white',
      borderTop:  '1px solid #9aa0a8',
      "& .MuiButton-textPrimary": {
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: '#9aa0a8',
      },
      "& .MuiButton-textSecondary": {
        color: '#9aa0a8',
        fontWeight: 'bold',
        border: '1px solid #9aa0a8',
      },
    }, 
     tree: {
      flexGrow: 1,
     // maxWidth: 400,
      border: '1px solid #9aa0a8',
      borderRadius: '5px',
      padding: '10px'
    },

}));

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} variant="outlined" {...props} ref={ref} />);

function TransitionRight(props) {
   return <Slide direction="left" {...props} />;
  }


const DataComponent = props => {

  const classes = useStyles();

   //STATES 
  const [data, setData] = React.useState([]); // Datos
  const [dataBr, setDataBr] = React.useState([]); // Migas
  const [openB, setOpenB] = React.useState(false); // Backdrop / Progress
  const [openD, setOpenD] = React.useState(false); // Diálogo de Borrar
  const [openM, setOpenM] = React.useState(false); // Message
  const [message, setMessage] = React.useState(''); // Texto message
  const [messageType, setMessageType] = React.useState(''); // Tipo message
  const [delObj, setDelete] = React.useState(''); // Ruta del objeto de borrado
  const [openForm, setOpenForm] = React.useState(false); // Diálogo de creación de carpeta
  const [nameFolder, setNameFolder] = React.useState(''); // Nombre de la carpeta a crear
  const [nameModel, setNameModel] = React.useState(''); // Nombre del modelo
  const [nameTarget, setNameTarget] = React.useState(''); // Nombre del destino
  const [typeRibosomu, setTypeRibosomu] = React.useState('00'); // Switch de creación de carpeta
  const [typeCatRibosomu, setTypeCatRibosomu] = React.useState('00'); // Switch de creación de carpeta
  const [typeData, setTypeData] = React.useState(''); // Desplegable de creación de carpeta
  const [delimeter, setDelimeter] = React.useState(' '); // Desplegable de delimitador
  const [dataTree, setDataTree] = React.useState([]); // Datos Tree
  const [service, setService] = React.useState(''); // Service

 // INIT LOAD
  React.useEffect(() => {
    setOpenB(true) 
    fetch(backend + "/API/aws/S3/list?path=" 
    , {
      headers: {
        'Authorization': props.auth.access_token
      }})
      .then(res => res.json())
      .then(res => {
          validateToken(res.status)
          setData(res)
          var initial_path = props.account.key + (props.user['custom:lakepath'] ?props.user['custom:lakepath']:'')
          var dataBrInit = [{
            Name: 'My ribosomu',
            Path: initial_path,
            Dataset: false,
            DatasetType: '',
            Datasourceid: '',
            Datasourcename: '',
            Datasourcearn: '',
            Datasetid: '',
            Datasetname: '',
            Datasetarn: '', 
            Ribosomu: '',
            Delimeter: ''
        }] 
        setDataBr(dataBrInit)
        setOpenB(false) 
          fetch(backend + "/API/aws/S3/listAll?path=" 
          , {
            headers: {
              'Authorization': props.auth.access_token
            }})
            .then(resp => resp.json())
            .then(resp => {
                validateToken(resp.status)
                setDataTree(resp)
            })
        })
      .catch(err => console.log(err.message))

  }, [])

  // # OBJETOS POR FILA
  const getGridListCols = () => {
    if (isWidthUp('xl', props.width)) {
      return 7;
    }
    if (isWidthUp('lg', props.width)) {
      return 6;
    }
    if (isWidthUp('md', props.width)) {
      return 5;
    }
    if (isWidthUp('sm', props.width)) {
      return 3;
    }

    return 2;
}

// MÉTODOS

//Navegación
function handleonClick(tile){
    
    if (tile.Type === 'folder'){

    setOpenB(true)  
    var record = {
        Name: tile.Name,
        Path: tile.Key,
        Dataset: tile.Dataset,
        DatasetType: tile.DatasetType,
        Datasourceid: tile.Datasourceid,
        Datasourcename: tile.Datasourcename,
        Datasourcearn: tile.Datasourcearn,
        Datasetid: tile.Datasetid,
        Datasetname: tile.Datasetname,
        Datasetarn: tile.Datasetarn,
        Ribosomu: tile.Ribosomu,
        Delimeter: tile.Delimeter
    }
    var dataaux = dataBr;
    dataaux.push(record)
    setDataBr(dataaux)
    fetch(backend + "/API/aws/S3/list?path=" + tile.Key , {
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then(res => res.json())
    .then(res => {
      validateToken(res.status)
        setData(res)
        setOpenB(false)  
    })

    .catch(err => console.log(err.message))
    } else {

      setOpenB(true)  
      fetch(backend + "/API/aws/S3/object?path=" + tile.Key , {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then(res => res.json())
      .then(res => {
        validateToken(res.status)
          setOpenB(false)  
          window.open(res);
      })
    }
 }


 const hiddenFileInput = React.useRef(null); 
 const handleonUpload = event => {
    hiddenFileInput.current.click();
 }


 // Para grabar un objeto, carpeta o ribosomu
function putObject(file,key,name,type,refresh,ribosomu) {

    fetch(backend + "/API/aws/S3/sign?path="+ key + name + "&fileType=" +  type, {
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then(res => res.json())
    .then(res => {
      validateToken(res.status)
        fetch(res, {
            method: 'PUT',
            headers: {
              'Content-Type': type
            },
            body: file
          })
          .then(res => res.json())
          .then(res => {
            validateToken(res.status)
            if (refresh !== false) { 
                refreshData(getKey())
            }
            if (ribosomu){
              createRibosomu(ribosomu);
            }
            createMessage('Object updated', 'success') 
          })
          .catch(err => {

            if (refresh !== false) { 
                refreshData(getKey())
            }
            if (ribosomu){
              createRibosomu(ribosomu);
            }
            createMessage('Object updated', 'success') 
        })
          
    })
 }

// Importar ficheros
 const handleonChange = event => {

    setOpenB(true)   
    createMessage('Uploading object ...', 'info') 
    const key = dataBr[dataBr.length - 1];
    const file = event.target.files[0];
    var name = file.name;
    var type = file.type;
    
    // Si es un modelo analítico comprobamos que el fichero sea del tipo definido
    if ( (key.DatasetType !== type.split('/')[1].toUpperCase()) && key.Datasetid === '' && key.Ribosomu === '01'){
      createMessage('El modelo analítico de tipo ' + key.DatasetType + ' y el fichero importado es ' + type.split('/')[1].toUpperCase(), 'info')
    } 
     putObject(file,key.Path,name,type)
  };

  function handleonDrop(file) {

    setOpenB(true)   
    createMessage('Uploading object ...', 'info')
    const keyH = dataBr[dataBr.length - 1];
    var name = file.name;
    var type = file.type;
    
    // Si es un modelo analítico comprobamos que el fichero sea del tipo definido
    if ( (keyH.DatasetType !== type.split('/')[1].toUpperCase()) && keyH.Datasetid === '' && keyH.Ribosomu === '01'){
      createMessage('El modelo analítico de tipo ' + keyH.DatasetType + ' y el fichero importado es ' + type.split('/')[1].toUpperCase(), 'info')
    } 
     putObject(file,keyH.Path,name,type)
  };

 function handleonClickBr(event) {

    event.preventDefault();
    var count = true;
    var j;
    var record = {};
    var dataaux = [];

    for (var i = 0; i < dataBr.length; i++){
        if (count === true) {
            j = i;
        }

        if (event.currentTarget.text === dataBr[i].Name) {  
            count = false;
            setOpenB(true)
            fetch(backend + "/API/aws/S3/list?path=" + dataBr[i].Path, {
              headers: {
                'Authorization': props.auth.access_token
              }})
            .then(res => res.json())
            .then(res => {
              validateToken(res.status)
                setData(res)
                setOpenB(false)  
            })
            .catch(err => console.log(err.message))
        }

        if ( i <= j){
      
            record = {
                Name: dataBr[i].Name,
                Path: dataBr[i].Path,
                Dataset: dataBr[i].Dataset,
                DatasetType: dataBr[i].DatasetType,
                Datasourceid: dataBr[i].Datasourceid,
                Datasourcename: dataBr[i].Datasourcename,
                Datasourcearn: dataBr[i].Datasourcearn,
                Datasetid: dataBr[i].Datasetid,
                Datasetname: dataBr[i].Datasetname,
                Datasetarn: dataBr[i].Datasetarn,
                Ribosomu: dataBr[i].Ribosomu,
                Delimeter: dataBr[i].Delimeter,
                RibosomuType: dataBr[i].RibosomuType
            }
            dataaux.push(record)
        }

    }
    console.log(dataaux)
    setDataBr(dataaux)

  }

  const handleOpenDialog = () => {
    setOpenD(true);
  };

  const handleCloseDialog = () => {
    setOpenD(false);
  };

  const handleCloseForm = () => {
    initForm();  
    setOpenForm(false);
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  function createRibosomu (data) {
    fetch(backend + "/API/aws/createribosomu?key=" + data.key +
    '&datasourcename=' + data.datasourcename + '&type=' + data.type + '&manifest=' + data.manifest +
    '&ribosomu=' + data.ribosomu + '&targetkey=' + data.targetkey + '&delimeter=' + data.delimeter + '&service=' + data.service, {
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then(res => res.json())
    .then(res => {
      validateToken(res.status)
        refreshData(getKey())
    })
    .catch(err => console.log(err.message))
  }

  function getKey(){
    return dataBr[dataBr.length - 1].Path;
  }


  function refreshData(key){
    setOpenB(true)    
    fetch(backend + "/API/aws/S3/list?path=" + key , {
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then(res => res.json())
    .then(res => {
      validateToken(res.status)
        setData(res)
        setOpenB(false)  
    })
  }

  const handleCreateFolder = () => { 
   
    setOpenForm(false);
    setOpenB(true);
    createMessage('Creating folder ...', 'info') 

    var key =  getKey();
    var name = nameFolder + '/';
    var prefix = 'https://ribosomu.s3-eu-west-1.amazonaws.com/'
    
    var dataSet = {
      key: key + name,
      datasourcename: nameModel,
      type: typeData,
      manifest: '',
      user: '',
      ribosomu: typeRibosomu,
      targetkey: nameTarget,
      delimeter: delimeter,
      service: service
  }

    if (typeRibosomu === '00'){
    putObject('',key,name,'', true)
    } else if (typeRibosomu !== '01') {
    putObject('',key,name,'', true, dataSet)  
    } else {
    putObject('',key,name,'', false)
    var data = {
        "fileLocations": [{
           "URIPrefixes": [prefix + key + name]
        }],
        "globalUploadSettings": {
            "format": typeData,
            "delimiter": delimeter,
            "textqualifier": '"',
            "containsHeader": 'true'
        }
    } 

    var nameManifest = (key + nameFolder + '.json').replace('/', '_');
    dataSet.manifest = props.user['custom:lakepath'] + 'Manifests/' + nameManifest
    putObject(JSON.stringify(data), props.user['custom:lakepath'] + 'Manifests/',nameManifest,'application/json',false, dataSet)
    }

  };

  const handleChangeName = event => {
    setNameFolder(event.target.value);
  };

  const handleChangeNameModel = event => {
    setNameModel(event.target.value);
  };

  const handleChangeNameTarget = (event, nodeId) => {
    console.log(nodeId)
    setNameTarget(nodeId);
  };

  const handleChangeSelect = event => {
    setTypeData(event.target.value);
  };

  const handleCloseMessage = event => {
    setOpenM(false)
  };

  const handleChangeTypeRibosomu = event => {
    setTypeRibosomu(event.target.value);
  };

  const handleChangeTypeCatRibosomu = event => {
    setTypeCatRibosomu(event.target.value);
  };

  const handleChangeDelimeter = event => {
    setDelimeter(event.target.value);
  };

  const handleChangeService = event => {
    setService(event.target.value);
  };

  const handleDelete = () => {

    var i = dataBr.length - 1;
    var key = dataBr[i].Path;

    createMessage('Deleting object ...', 'info') 
    setOpenD(false);
    setOpenB(true); 
    fetch(backend + "/API/aws/S3/delete?path=" + delObj , {
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then(res => res.json())
    .then(res => {
      validateToken(res.status)
        fetch(backend + "/API/aws/S3/list?path=" + key , {
          headers: {
            'Authorization': props.auth.access_token
          }})
        .then(res => res.json())
        .then(res => {
            setData(res)
            setOpenB(false)  
            createMessage('Object deleted', 'success') 
        })
    })
  }

  function initForm() {
    setTypeData('');
    setTypeRibosomu('00');
    setNameFolder('');
    setNameModel('')
    setNameTarget('')
    setDelimeter(' ')
  }

  function createMessage(message, type){
    setMessage(message)
    setMessageType(type)
    setOpenM(true)
  }


 const onDrop = acceptedFiles => {
    acceptedFiles.forEach((file) => {
    handleonDrop(file)
    })
  }
  const {getRootProps, isDragActive} = useDropzone({onDrop})

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );
 
  return (
 
    <div className={classes.root} >
     <Backdrop className={classes.backdrop} open={openB}>
        <CircularProgress color="inherit" />
    </Backdrop>  
    <Breadcrumbs className={classes.bread} aria-label="Breadcrumb">
          {dataBr.map((bread) => (
             <Link color = "inherit" href=">" onClick={handleonClickBr} >
               {bread.Name}
            </Link>))}
     </Breadcrumbs>
     <Snackbar open={openM} autoHideDuration={5000} onClose={handleCloseMessage}
      anchorOrigin = {{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent = {TransitionRight}
     >
     <Alert severity={messageType}>
             {message}
        </Alert>
        </Snackbar>

      <GridList {...getRootProps()} cellHeight={180} className={isDragActive?classes.gridActive:classes.gridList} cols= {getGridListCols()} spacing={50}> 
        
        {data.map((tile) => (
          
          <GridListTile key={tile.Key} >
             <a href = "#">
            <img className={classes.image} src= {tile.Image}  onClick={() => { handleonClick(tile) }} />
             </a> 
            <GridListTileBar 
              className= {classes.text}
              title={tile.Name}
              subtitle= {<span> { tile.Type !== 'folder' ? moment(tile.LastModified).format('DD/MM/YYYY HH:mm') : tile.RibosomuType} </span>}
              actionIcon={ 
                <div>
                {/* {(props.user['custom:author'] === true || ( props.user['custom:author'] === false && tile.Type !== 'folder' )) &&  */}
                <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}  onClick={() => {handleOpenDialog(); setDelete(tile.Key)}} >
                <DeleteIcon/> 
                </IconButton> 
                 {/* } */}
                </div>
              }
            />
          </GridListTile>
        ))}
      </GridList>
      <input type="file" style={{display: 'none'}} ref={hiddenFileInput}
        onChange={handleonChange}/>
      <Fab className= {classes.buttonFile} color="primary" aria-label="add" onClick={handleonUpload}> 
        <AddIcon className= {classes.fabIcon}/>
      </Fab>
       {/* {props.user['custom:author'] === true && */}
      <Fab className= {classes.buttonFolder} color="primary" aria-label="add" onClick={handleOpenForm}> 
        <CreateNewFolder className= {classes.fabIcon}/>
      </Fab> 
       {/* } */}
      <Dialog  
        fullWidth = {true} maxWidth = {'sm'}
        open={openD}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className= {classes.dialogTitle} id="alert-dialog-title"> {"Deleting object " + delObj}</DialogTitle>
        <DialogContent> 
          <DialogContentText id="alert-dialog-description">
              If you delete the selected object, you will not be able to retrieve it again. In case you want to delete a folder, you must first delete all the objects that are inside it.
          </DialogContentText>
        </DialogContent>
        <DialogActions className ={classes.dialogActions}>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth = {true} maxWidth = {'sm'}
        open={openD} open={openForm} onClose={handleCloseForm} aria-labelledby="form-dialog-title">
        <DialogTitle className= {classes.dialogTitle} id="form-dialog-title">Create Ribosomu</DialogTitle>
        <DialogContent> 
          <DialogContentText>
          </DialogContentText>
        <FormGroup className= {classes.dialog} column>
          <TextField
            variant = 'outlined'
            id="name"
            label="Name"
            type="text"
            fullWidth
            onChange={handleChangeName}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
          </FormGroup>
      <FormGroup className= {classes.dialog} column>    
      <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="label1">Category</InputLabel>
        <Select 
          labelId="selectlabel1"
          variant="outlined"
          id="selectCategoria"
          label='Category'
          value={typeCatRibosomu?typeCatRibosomu:'00'}
          onChange={handleChangeTypeCatRibosomu}
        >
          <MenuItem value={'00'}>Library</MenuItem>
          {props.user['custom:author'] === true &&
          <MenuItem value={'01'}>Computing</MenuItem>
          }
        </Select>
        </FormControl>
        </FormGroup>
        <FormGroup className= {classes.dialog} column> 
        <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="label1">Type</InputLabel>
        <Select 
          labelId="selectlabel2"
          variant="outlined"
          id="selectType"
          label='Type'
          value={typeRibosomu}
          onChange={handleChangeTypeRibosomu}
        >
          {typeCatRibosomu === '00' &&  
          <MenuItem value={'00'}>Objects</MenuItem>}
          {typeCatRibosomu === '01' &&  
          <MenuItem value={'01'}>Analytics</MenuItem>}
          {typeCatRibosomu === '01' &&  
          <MenuItem value={'02'}>Speech to Text</MenuItem>}
          {typeCatRibosomu === '01' &&  
          <MenuItem value={'03'}>Image Processing</MenuItem>}
          {typeCatRibosomu === '01' &&  
          <MenuItem value={'04'}>Job ETL</MenuItem>}
          {typeCatRibosomu === '01' &&  
          <MenuItem value={'05'}>SQL Process</MenuItem>}
          {typeCatRibosomu === '00' && 
          <MenuItem value={'06'}>API</MenuItem>}
           {typeCatRibosomu === '00' && 
          <MenuItem value={'07'}>ML Models</MenuItem>}
           {typeCatRibosomu === '00' && 
          <MenuItem value={'08'}>ETL</MenuItem>}
        </Select>
       </FormControl>
       </FormGroup>
       {typeRibosomu === '01' && 
        <FormGroup className= {classes.dialog} column> 
         <TextField
                 autoFocus
                 variant="outlined"
                 id="name"
                 label="Model name"
                 type="text"
                 fullWidth
                 onChange={handleChangeNameModel}
                 InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
       /> 
        </FormGroup>      
       }

       {typeRibosomu === '01' &&  
       
       <FormGroup className= {classes.dialog} column> 
       <FormControl variant="outlined" className={classes.formControl}>  
       <InputLabel className= {classes.labelRoot} id="label1">Format</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label='Format'
          value={typeData}
          onChange={handleChangeSelect}
        >
          <MenuItem value={'JSON'}>json</MenuItem>
          <MenuItem value={'CSV'}>csv</MenuItem>
        </Select>
       </FormControl> 
       </FormGroup>
       }
       {typeRibosomu === '01' &&  
        <FormGroup className= {classes.dialog} column> 
        <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="label1">Delimeter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label='Delimeter'
          value={delimeter}
          onChange={handleChangeDelimeter}
        >
          <MenuItem value={' '}>Space</MenuItem>
          <MenuItem value={','}>,</MenuItem>
          <MenuItem value={';'}>;</MenuItem>
        </Select>
       </FormControl> 
       </FormGroup>
       }
              {typeRibosomu === '03' &&  
        <FormGroup className= {classes.dialog} column> 
        <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="labelServ">ML Model</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label='labelServ'
          value={service}
          onChange={handleChangeService}
        >
          <MenuItem value={'01'}>Brest Thermography</MenuItem>
          <MenuItem value={'02'}>Carcinoma</MenuItem>
          <MenuItem value={'03'}>Pulmonary Disease</MenuItem>
          <MenuItem value={'04'}>Radiology</MenuItem>
          <MenuItem value={'05'}>Rinitis</MenuItem>
        </Select>
       </FormControl> 
       </FormGroup>
       }
       {(typeRibosomu === '02' || typeRibosomu === '03') && 
        <FormGroup className= {classes.dialog} column>  
         <InputLabel  className= {classes.label} id="labelTree">Target path</InputLabel> 
          <TreeView
          className={classes.tree}
          label='labelTree'
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect = {handleChangeNameTarget}
        >
          {renderTree(dataTree)}
        </TreeView>
        </FormGroup>
       }
        </DialogContent>
        <DialogActions className ={classes.dialogActions}>
          <Button onClick={handleCloseForm} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateFolder} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  }


export default withWidth() (DataComponent);