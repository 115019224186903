import React from 'react';
import Iframe from 'react-iframe'
import { StyleSheet, css } from 'aphrodite';
import { Settings } from '../settings.js';

const styles = StyleSheet.create({
    iframe: {
        border: 0 
    },
    content: {
        height: '100%'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '60%'
    },
    imageContainer:{
        backgroundColor: '#0E1E30',
        height: '900px'
    }
});

class APIComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
      }


    componentDidMount() {
        setTimeout(function() { //Start the timer
       fetch(Settings.backendPath + '/api/ribosomu/action?action=' 
       + this.props.match.params.action + '&id=' + this.props.match.params.id, {
        headers: {
          'Authorization': this.state.auth.access_token
        }})
                        .then( response => response.json())
                        .then( data => { 
                           this.setState({data: data});
                        });   
        }.bind(this), 10000)             
    }

    componentWillUnmount() {
    }

    render() {

            if (this.state.data) {
            return (
             'Response: ' + JSON.stringify(this.state.data)
            );
            } else {
                return (
                <div className= {css(styles.imageContainer)}>
                <img src='https://i.pinimg.com/originals/e9/29/1e/e9291eaddacd460280a34a151dcc5cc4.gif' className= {css(styles.image)} />
                </div>    
             );              
            }
    }
}

export default APIComponent;
