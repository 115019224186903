import React from 'react';
import { Row, Column } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import Logo from '../../assets/icon-logo';

const styles = StyleSheet.create({
    container: {
        marginLeft: 32,
        marginRight: 32,
        marginTop: 20,
        height: '70px'
    },
    title: {
        fontFamily: 'Muli',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0.4px',
        color: '#a0a0a0',
        opacity: 1,
        marginLeft: 1,
        marginBottom: 5
    }
});

function LogoComponent(props) {
   
    var url = props.logo;

    if (url === ''){

       return( 
        <Column className={css(styles.container)} horizontal="center" vertical="center">
            <span className={css(styles.title)}>RIBOSOMU</span>
        </Column>
       );

    }  else {  

    return (
        <Column className={css(styles.container)} horizontal="center" vertical="center">
            <span className={css(styles.title)}>RIBOSOMU</span>
            <img src={url} width= '150px'/>
        </Column>
    );
    }
}

export default LogoComponent;
