import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Column, Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import LogoComponent from './LogoComponent';
import MenuItemComponent from './MenuItemComponent';
import IconBurger from '../../assets/icon-burger';
import IconSettings from '@material-ui/icons/BuildTwoTone';
import IconReport from '@material-ui/icons/AssessmentTwoTone';
import IconHome from '@material-ui/icons/HomeTwoTone';
import IconLog from '@material-ui/icons/ReportTwoTone';
import IconData from '@material-ui/icons/StorageTwoTone';
import IconAPI from '@material-ui/icons/CodeTwoTone';
import IconUsers from '@material-ui/icons/GroupTwoTone';
import MenuOpenIcon from '@material-ui/icons/MenuOpenTwoTone';
import Button from '@material-ui/core/Button';

const styles = StyleSheet.create({
    burgerIcon: {
        cursor: 'pointer',
        position: 'absolute',
        left: 24,
        top: 10,
    },
    burgerIconClosed: {
        cursor: 'pointer',
        position: 'absolute',
        left: 140,
        color: 'rgb(191, 191, 191)'
    },
    container: {
        backgroundColor: 'white',
        width: 200,
        paddingTop: 32,
        height: 'calc(100% - 32px)',
        boxShadow: '5px 10px 18px #888888'
    },
    containerMobile: {
        transition: 'left 0.5s, right 0.5s',
        position: 'absolute',
        width: 200,
        height: 'calc(100% - 32px)',
        zIndex: 901
    },
    mainContainer: {
        height: '100%',
        minHeight: '100vh'
    },
    mainContainerMobile: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    mainContainerExpanded: {
        width: '100%',
        minWidth: '100vh'
    },
    menuItemList: {
        marginTop: 52
    },
    outsideLayer: {
        position: 'absolute',
        width: '100vw',
        minWidth: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,.50)',
        zIndex: 900
    },
    separator: {
        borderTop: '1px solid #DFE0EB',
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    },
    hide: {
        left: -210
    },
    show: {
        left: 0
    }
});

function SidebarComponent({action, onChange, selectedItem, user, account}) {
    const [expanded, setExpanded] = useState(false);
    const [show, setShow] = useState(true);
    const [widthC, setWidthC] = useState(1268)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= widthC);
    const input1 = useRef(null);

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setIsMobile(window.innerWidth <= widthC);
        if (window.innerWidth <= widthC){
        setShow(true)
        } else {
        setShow(false)
        }
        forceUpdate();
    }, [window.innerWidth]);

    const onItemClicked = item => {
        setExpanded(false);
        action('');
        return onChange(item);
    };

    const toggleMenu = () => {
      if (window.innerWidth <= 1268 ) {
       setExpanded(!expanded)
      }
       else { 
       setWidthC(1268)
       setShow(false)
       setExpanded(false)
       }
    }

    const showBurguer = () => {
        setWidthC(2268)
        setShow(true)
        setIsMobile(window.innerWidth <= 2268);
      }

    const renderBurger = () => {
        return (
            <div>
                 {show && 
                   <Button onClick={toggleMenu}>
                   <IconBurger/>
                   </Button>}
            </div>
        );
    };

    return (
        <div style={{ position: 'relative' }}>
            <Row
                componentRef={element => (input1.current = element)}
                className={css(styles.mainContainer)}
                breakpoints={{
                    [widthC]: css(
                        styles.mainContainerMobile,
                        expanded && styles.mainContainerExpanded
                    )
                }}
            >
                 {!show && 
                   <Button onClick={showBurguer} className={css(styles.burgerIconClosed)}>
                   <MenuOpenIcon/>
                   </Button>}  
                {isMobile && !expanded && renderBurger()}
                <Column
                    className={css(styles.container)}
                    breakpoints={{
                        [widthC]: css(
                            styles.containerMobile,
                            expanded ? styles.show : styles.hide
                        )
                    }}
                >
                    <LogoComponent logo = {account.logo}/>
                    <Column className={css(styles.menuItemList)}>
                        <MenuItemComponent
                            title="Home"
                            icon={IconHome}
                            onClick={() => onItemClicked('Home')}
                            active={selectedItem === 'Home'}
                        />
                        <MenuItemComponent
                            title="Reports"
                            icon={IconReport}
                            onClick={() => onItemClicked('Reports')}
                            active={selectedItem === 'Reports'}
                        />
                        <MenuItemComponent
                            title="Data"
                            icon={IconData}
                            onClick={() => onItemClicked('Data')}
                            active={selectedItem === 'Data'}
                        />
                        {user['custom:author'] === true &&
                        <MenuItemComponent
                            title="Designer"
                            icon={IconSettings}
                            onClick={() => onItemClicked('Designer')}
                            active={selectedItem === 'Designer'}
                        /> }

                        <MenuItemComponent
                            title="APIs"
                            icon={IconAPI}
                            onClick={() => onItemClicked('APIs')}
                            active={selectedItem === 'APIs'}
                        />
                       
                       {user['custom:admin'] === true &&
                       <MenuItemComponent
                            title="Users"
                            icon={IconUsers}
                            onClick={() => onItemClicked('Users')}
                            active={selectedItem === 'Users'}
                        />}
                       {user['custom:admin'] === true &&
                        <MenuItemComponent
                        title="Logs"
                        icon={IconLog}
                        onClick={() => onItemClicked('Logs')}
                        active={selectedItem === 'Logs'}
                       />}

                        <div className={css(styles.separator)}></div>


                    </Column>
                </Column>
                {(isMobile && expanded) && (
                    <div
                        className={css(styles.outsideLayer)}
                        onClick={toggleMenu}
                    ></div>
                )}
            </Row>
        </div>
    );
}

export default SidebarComponent;
