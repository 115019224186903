import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Settings } from '../settings.js';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { validateToken } from '../functions.js';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: 'none',
      marginLeft: '20px',
      marginRight: '25px',
    },

    "& .MuiTable-root": {
      marginTop: '20px'
    },

    "& .MuiTableCell-head": {
      fontWeight: 'bold',
      backgroundColor: 'white',
      color: 'black',
      fontSize: '18px',
      paddingBottom: '15px !important'
    },

    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":{
      color: 'black'
    },

    "& .MuiTableSortLabel-root.MuiTableSortLabel-active":{
      color: 'black'
    },

    "& .MuiTableCell-root": {
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingLeft: '1px',
    },

    "& .MuiAlert-filledSuccess" :{
      backgroundColor: '#CCF0D5',
      color: 'black'
    },
    "& .MuiAlert-filledError": {
     backgroundColor: '#F2CED2',
     color: 'black'
   },
   "& .MuiAlert-filledInfo": {
     backgroundColor: '#96C1F4',
     color: 'white'
   },
   "& .MuiFormLabel-root": {
    fontWeight: 'bold',
    color: '#9aa0a8' 
  },
  "& .MuiInputBase-input":{
    background: 'white'
  }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#F4F4F4'
  },
  dialog: {
  margin: '20px',
  backgroundColor: 'white'
  },

  contentResponse:{
    margin: '20px',
    backgroundColor: 'white',
    padding: '15px',
    border: '1px solid black'
  },

  label: {
    marginTop: '8px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#9aa0a8'
  },
  area: {
    marginTop: '8px',
    fontSize: '13px',
    border: '1px solid lightgray',
    borderRadius: '5px'
  },
  labelRoot: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#9aa0a8'
  },
  dialogTitle:{
    //backgroundColor: '#9aa0a8',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#9aa0a8',
    borderBottom: '2px solid #88929C'
  },
  dialogContent:{
   backgroundColor: 'white',
  },
  switch:{
    marginLeft: '10px',
   },
  dialogActions:{
    backgroundColor: 'white',
    borderTop:  '1px solid #9aa0a8',
    "& .MuiButton-textPrimary": {
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#9aa0a8',
    },
    "& .MuiButton-textSecondary": {
      color: '#9aa0a8',
      fontWeight: 'bold',
      border: '1px solid #9aa0a8'
    },
    areaResponse:{
      backgroundColor: '#F0F0F0'
    },

  },
}));

const tableIcons = {
 // Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
 // Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



function UsersComponent(props) {

  const classes = useStyles();

  var columns = [
    {title: "", width: "60px", render: rowData => <Avatar maxInitials={1} size={35} round={true} src={rowData === undefined ? "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/User_font_awesome.svg/1200px-User_font_awesome.svg.png" :rowData['custom:image']} name={rowData === undefined ? " " : rowData.Username}   /> },
    {title: "User", width: 20, field: "Username", defaultSort: 'asc', editable: 'onAdd'},
    {title: "Status", width: 15,field: "UserStatus", editable: 'never'},
    {title: "Mail", width: 20,field: "email", defaultSort: 'asc', editable: 'onAdd', hiddenByColumnsButton: true, hidden: true},
    {title: "Department",width: 10, field: 'custom:department'},
    {title: "Position",width: 10, field: 'custom:position'},
    {title: "Path",width: 10, field: 'custom:lakepath'},
    {title: "Author", width: 10, field: "custom:author", type: 'boolean'},
    {title: "Admin", width: 10,field: "custom:admin", type: 'boolean'},
    {title: "Modified", width: 10,field: "UserLastModifiedDate", type: 'datetime', editable: 'never'},
    {title: "Name", width: 10, field: "custom:name", hiddenByColumnsButton: true, hidden: true},
    {title: "Company", field: 'custom:company', hiddenByColumnsButton: true, hidden: true},
    {title: "AWS", field: 'custom:awsuser', hiddenByColumnsButton: true, hidden: true},
    {title: "Home", field: "custom:home", cellStyle: {fontSize:11}, hiddenByColumnsButton: true, hidden: true},
    {title: "Imagen", field: "custom:image", hiddenByColumnsButton: true, hidden: true, export: false, cellStyle: {fontSize:11, whiteSpace: 'nowrap',  overflow: 'auto', width: 80, maxWidth: 80}, headerStyle: { width:80,maxWidth: 80}},
  ]

  const [data, setData] = useState([]); //table data
  const [openB, setOpenB] = React.useState(false); //progress
  const [action, setAction] = useState([]);
  const [actionT, setActionT] = useState([]);
  const [actionE, setActionE] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [user, setUser] = React.useState({});
  const [switchAuthor, setSwitchAuthor] = React.useState();
  const [switchAdmin, setSwitchAdmin] = React.useState();
  const [editI, setEditI] = React.useState(true);

  var actionsTable = []

  useEffect(() => {
    
    if (props.user['custom:admin'] === true) {
       actionsTable.push({
        icon:  () => <Edit/>,
        tooltip: 'Edit',
        onClick: (event, rowData) => {
          new Promise((resolve) => {
          handleEdit(rowData, resolve)
         })
        }
      })
    }

    if (props.user['custom:admin'] === true) {
      actionsTable.push({
        icon:  () => <AddBox/>,
        tooltip: 'Add',
        isFreeAction: true,
        onClick: (event, rowData) => {
            handleNew()
        }
      })
    }  
      
  setActionT(actionsTable)

  if (props.user['custom:admin'] === true) {
  setActionE({
  onRowDelete: (oldData) =>
    new Promise((resolve) => {
      handleRowDelete(oldData, resolve)
    }),
  })
  }

  setOpenB(true) 
  fetch(Settings.backendPath + '/API/aws/cognito/list', {
    headers: {
      'Authorization': props.auth.access_token
    }})
  .then( res => res.json())
  .then( res => { 
    validateToken(res.status)
    setData(res)
    setOpenB(false) 
   });},[]);

    //NEW
    const handleNew = () => {
      setAction('new')
      setEditI(false)
      var newUser = {
      }
      setUser(newUser)
      setOpenDialog(true);

    }

    //EDIT
    const handleEdit = (data) => {
      setAction('edit')
      setEditI(true)
      setUser(data);
      setOpenDialog(true);
    }

  const handleRowUpdate = (newData, resolve) => {

    //validation
   let errorList = []
   var dataN = []
   var data_aux = {}

   if(errorList.length < 1){

   for (var i = 0; i < Object.keys(newData).length ; i++){
   if (Object.keys(newData)[i].includes("custom:") === true) {
    data_aux = {
      Name: Object.keys(newData)[i],
      Value: newData[Object.keys(newData)[i]].toString()
    }
     dataN.push(data_aux)
   }
   }

    var body = {
      user: newData.Username,
      data: dataN
    }
    setOpenDialog(false)
    fetch(Settings.backendPath + '/API/AWS/cognito/update', {
      method: 'POST',
      body: JSON.stringify(body), 
      headers:{
        'Content-Type': 'application/json',
        'Authorization': props.auth.access_token
      }
    })
    .then( res => res.json())
    .then( res => { 
      validateToken(res.status)
      fetch(Settings.backendPath + "/API/aws/cognito/list", {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        setData(res)
        setOpenB(false) 
       })
     })

     } else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    } 
    
  }

  //ADD
  const handleRowAdd = (newData, resolve) => {

    //validation
    let errorList = []
    var dataN = []
    var data_aux = {}

   if(errorList.length < 1){ 

    for (var i = 0; i < Object.keys(newData).length ; i++){
      console.log(Object.keys(newData)[i])
      if (Object.keys(newData)[i] !== 'Username') {
        data_aux = {
         Name: Object.keys(newData)[i],
         Value: newData[Object.keys(newData)[i]].toString()
        }
        dataN.push(data_aux)
      }
    }
   
       var body = {
         user: newData.Username,
         data: dataN
       }

    setOpenDialog(false)   
    fetch(Settings.backendPath + '/API/AWS/cognito/create', {
      method: 'POST', 
      body: JSON.stringify(body), 
      headers:{
        'Content-Type': 'application/json',
        'Authorization': props.auth.access_token
      }
    })
    .then( res => res.json())
    .then( res => { 
      validateToken(res.status)
      fetch(Settings.backendPath + "/API/aws/cognito/list", {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        setData(res)
        setOpenB(false) 
       })
     })

    } else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
    
  }

  //DELETE
  const handleRowDelete = (oldData, resolve) => {
    fetch(Settings.backendPath + '/API/ribosomu/user/delete?userid=' + oldData.Username, {
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then( res => res.json())
    .then( res => { 
      validateToken(res.status)
      const dataDelete = [...data];
      const index = oldData.tableData.Username;
      dataDelete.splice(index, 1);
      setData([...dataDelete]);
      resolve()
     });

  }

      //CLOSE DIALOG
      const handleCloseDialog = (event, reason) => {
        setOpenDialog(false)
      };

    //SAVE
    const handleSave = (resolve) => {
      if (action === 'edit'){
        handleRowUpdate(user, resolve);
      } 
      else if (action === 'new'){
        handleRowAdd(user, resolve);
      }
    }

   //INPUTS y AREAS
    const handleChanges = event => {
          var userNew = user;
          userNew[event.target.id != undefined? event.target.id: 'method'] = event.target.value
          setUser(userNew);
        };

    const handleChangeSwitchAuthor = (event) => {
          setSwitchAuthor(event.target.checked);
          var userNew = user;
          userNew[event.target.name]  = event.target.checked
          setUser(userNew);  
        };    
    const handleChangeSwitchAdmin = (event) => {
          setSwitchAdmin(event.target.checked);
          var userNew = user;
          userNew[event.target.name]  = event.target.checked
          setUser(userNew);  
        };    


  return (
    <div className={classes.root}>
       <Backdrop className={classes.backdrop} open={openB}>
         <CircularProgress color="inherit" />
       </Backdrop>  
       <Grid container spacing={2}>
       <Grid item xs={12}>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable 
              title=""
              columns={columns}
              actions = {actionT}
              data={data}
              icons={tableIcons}
              options = {
                {pageSize: 10,
                exportButton: true,
                rowStyle: { fontSize: 14},
                columnsButton: true
              }
              }
              editable={actionE}
            />
            </Grid>
            </Grid>
     <Dialog fullWidth={true} maxWidth = {'sm'} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle className= {classes.dialogTitle} id="form-dialog-title">
        <Avatar maxInitials={1} size={40} round={true} src={user['custom:image'] === undefined ? "https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png" :user['custom:image']} name={user === undefined ? " " : user.Username}   /> 
          {' ' + (user['custom:name']?user['custom:name']:' New user')}
        </DialogTitle>
        <DialogContent className= {classes.dialogContent}> 
          <DialogContentText>
          </DialogContentText>
          <Grid container>
          <Grid item xs={12}>
          <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="Username"
            label="User"
            type="text"
            disabled = {editI}
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user.Username}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="email"
            label="E-mail"
            type="text"
            autoFocus={false}
            fullWidth
            disabled = {editI}
            onChange={handleChanges}
            defaultValue = {user.email}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:name"
            label="User name"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:name']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:company"
            label="Company"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:company']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            label="Department"
            id="custom:department"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:department']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:position"
            label="Position"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:position']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 

        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:lakepath"
            label="Data path authorization"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:lakepath']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:awsuser"
            label="AWS User"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:awsuser']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:image"
            label="Image"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            multiline = {true}
            defaultValue = {user['custom:image']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="custom:home"
            label="Home"
            type="text"
            autoFocus={false}
            multiline = {true}
            fullWidth
            onChange={handleChanges}
            defaultValue = {user['custom:home']}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
        <FormGroup row> 
        <FormControlLabel
          className= {classes.switch}
          control={<Switch checked={user['custom:author']} onChange={handleChangeSwitchAuthor} name="custom:author" />}
          label="Author"
        />  
        <FormControlLabel
          className= {classes.switch}
          control={<Switch checked={user['custom:admin']} onChange={handleChangeSwitchAdmin} name="custom:admin" />}
          label="Admin"
        />  
        </FormGroup>
        
        </Grid>
        </Grid>
        </DialogContent>
        <DialogActions className ={classes.dialogActions}> 
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UsersComponent;
