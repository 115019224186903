import React from 'react';
import Iframe from 'react-iframe'
import { StyleSheet, css } from 'aphrodite';
import { Settings } from '../settings.js';
import { validateToken } from '../functions.js';

const styles = StyleSheet.create({
    iframe: {
        border: 0 
    },
    content: {
        height: '100%',
        marginTop: '0px'
    }
});

class ContentComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          url: '',
          id: ''
        };
      }

    componentDidMount() {

        fetch(Settings.backendPath + '/API/aws/quicksight?id=' + this.props.id, {
            headers: {
              'Authorization': this.props.auth.access_token
            }})
                        .then( response => response.json())
                        .then( data => { 
                            validateToken(data.status)
                            this.setState({url: data});
                        });                 
    }

    componentWillUnmount() {
    }

    render() {
            return (
                <div className={css(styles.content)}> 
                <Iframe url={this.state.url} width="100%" height="100%" id="myId" className= {css(styles.iframe)} display="initial" position="relative"/> </div> 
            );
    }
}

export default ContentComponent;
