import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Settings } from '../settings.js';
import { validateToken } from '../functions.js';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
const backend = Settings.backendPath;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    "& .MuiGridListTile-tile":{
      boxShadow: '5px 5px 5px #A0A0A0'
    },
    "& .MuiGridListTile-root":{
      height: '180px !important'
  },
    "& .MuiGridListTile-imgFullHeight":{
      opacity:0.8
    },
    "& .MuiGridList-root":{
      marginTop: '20px !important'
    },
    "& .MuiGridListTileBar-rootSubtitle":{
      height: 40
    },      "& .MuiGridListTileBar-title" : {
      fontSize: '0.80rem',
      fontWeight: 'bold'
  },
  "& .MuiGridListTileBar-subtitle" : {
      fontSize: '0.60rem',
      marginBottom: 8
  },
    
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'white',
  },

  image: {
    width: '100%'
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#F4F4F4'
  },
}));

const TitlebarGridList = props => {

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [openB, setOpenB] = React.useState(false);

  React.useEffect(() => {
    setOpenB(true)  
    fetch(backend + "/API/aws/quicksight/list?cat=" + props.user['custom:account'] , {
      headers: {
        'Authorization': props.auth.access_token
      }})
      .then(res => res.json())
      .then(res => {
        validateToken(res.status)
        setData(res)
        setOpenB(false)  
      })
      .catch(err => console.log(err.message))
  }, [])

  const getGridListCols = () => {
    if (isWidthUp('xl', props.width)) {
      return 6;
    }

    if (isWidthUp('lg', props.width)) {
      return 5;
    }

    if (isWidthUp('md', props.width)) {
      return 5;
    }

    if (isWidthUp('sm', props.width)) {
      return 3;
    }

    return 1;
}


  return (
    
    <div className={classes.root}>
    <Backdrop className={classes.backdrop} open={openB}>
        <CircularProgress color="inherit" />
    </Backdrop>  
      <GridList cellHeight={180} className={classes.gridList} cols= {getGridListCols()} spacing={50}>

        {data.map((tile) => (
          <GridListTile key={tile.Name}>
            <a href = "#">
            <img className={classes.image} 
            // Las imagenes van con su grupo en el bucket de ribosomu/images
            src = {`https://ribosomu.s3.eu-west-1.amazonaws.com/images/${tile.Name.split('.')[0].toLowerCase()}.jpeg`}
            alt={tile.Name} 
            onClick={() => { props.action(tile.DashboardId); }}/>
            </a>
            <GridListTileBar className={classes.text}
              title={tile.Name.split('.')[1]}
              subtitle={<span> {moment(tile.LastUpdatedTime).format('DD/MM/YYYY HH:mm')}</span>}
           //   actionIcon={
           //     <IconButton aria-label={`info about ${tile.title}`} className={classes.icon} onClick={() => { props.action(tile.DashboardId); }} >
           //       <InfoIcon />
           //     </IconButton>
           //   }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
  
}

export default withWidth() (TitlebarGridList)