import React from 'react';
import ContentComponent from './ContentComponent';
import HomeComponent from './HomeComponent';
import LogComponent from './LogComponent';
import GridComponent from './GridComponent';
import TableComponentAPI from './TableComponentAPI';
import DesignerComponent from './DesignerComponent';
import UsersComponent from './UsersComponent';
import DataComponent from './DataComponent';

class Container extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
             
        };
      }

      render() {
       if (this.props.item === 'Reports'){
         if (this.props.id == ''){
            return (
              <GridComponent auth = {this.props.auth}
                             user = {this.props.user} 
                           action = {this.props.action}/> 
            )
          } else {
            return (
              <ContentComponent 
              auth = {this.props.auth}
              user = {this.props.user}
              id = {this.props.id}/> 
            )
        }

       } 
       if (this.props.item === 'APIs'){
        return (
          <TableComponentAPI 
          auth = {this.props.auth}
          user = {this.props.user} /> 
        )
       }

       if (this.props.item === 'Designer'){
        return (
          <DesignerComponent 
          auth = {this.props.auth}
          user = {this.props.user}/> 
        )
       }

       if (this.props.item === 'Home'){
        if (this.props.user['custom:home'] === undefined){
          return (<div></div>)
        } else { 

          return (
            <HomeComponent 
            auth = {this.props.auth}
            user = {this.props.user}
            id = {this.props.user['custom:home']}/> 
          )
        }
       }

       if (this.props.item === 'Users'){
        return (
          <UsersComponent 
          auth = {this.props.auth}
          user = {this.props.user}/> 
        )
       }

       if (this.props.item === 'Logs'){
        return (
          <LogComponent 
          auth = {this.props.auth}
          user = {this.props.user}
          id = '1b2a1b09-58e4-4607-98bf-772868901dca'/> 
        )
       }

       if (this.props.item === 'Data'){
        return (
          <DataComponent 
          auth = {this.props.auth}
          account = {this.props.account} 
          user = {this.props.user} /> 
        )
       }

      }  

}

export default Container;