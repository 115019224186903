
  function getType(value){
    var originalType = (typeof value).toUpperCase()
    var type = originalType
    
    switch (originalType) {
      case 'NUMBER':
         type = isInt(value) === true ? 'INTEGER' : 'DECIMAL'
        break;
      case 'STRING':
         type = isValidDate(value) === true ? 'DATETIME' : 'STRING'
        break;
      case 'BOOLEAN':
         type = 'BOOLEAN'
      break;
    }
   
    return type
  }

  function isInt(n) {
    var r = n % 1 === 0;
    return r
 }

 function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function getFileContent(file) {

    return new Promise((resolve, reject) => {
      let content = '';
      const reader = new FileReader();

      reader.onloadend = function(e) {
        content = e.target.result;
        resolve(content);
      };

      reader.onerror = function(e) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }


  function validateToken(status){
    if (status == '401' || status == 401){
      var protocol = 'https://'
      if (window.location.host.includes('localhost') === true){
        protocol = 'http://'
      } 
      window.location.href = protocol + window.location.host
    }
  }

  module.exports = {
    validateToken
  }