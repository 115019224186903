import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import Cached from '@material-ui/icons/Cached';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/core/styles';
import { Settings } from '../settings.js';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReactJson from 'react-json-view'
import Switch from '@material-ui/core/Switch';
import Slide from '@material-ui/core/Slide';
import { validateToken } from '../functions.js';
const path = '/api/ribosomu/api'


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: 'none',
      marginLeft: '20px',
      marginRight: '25px',
    },

    "& .MuiTable-root": {
      marginTop: '20px'
    },

    "& .MuiTableCell-head": {
      fontWeight: 'bold',
      backgroundColor: 'white',
      color: 'black',
      fontSize: '18px',
      paddingBottom: '15px !important'
    },

    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":{
      color: 'black'
    },

    "& .MuiTableSortLabel-root.MuiTableSortLabel-active":{
      color: 'black'
    },

    "& .MuiTableCell-root": {
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingLeft: '1px',
    },

    "& .MuiAlert-outlinedInfo" :{
      backgroundColor: 'white',
    },
    "& .MuiAlert-outlinedSuccess" :{
      backgroundColor: 'white',
    },
    "& .MuiAlert-outlinedError" :{
      backgroundColor: 'white',
    },
   "& .MuiFormLabel-root": {
    fontWeight: 'bold',
    color: '#9aa0a8' 
  },
  "& .MuiInputBase-input":{
    background: 'white'
  }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#F4F4F4'
  },
  dialog: {
  margin: '20px',
  backgroundColor: 'white'
  },

  contentResponse:{
    margin: '20px',
    backgroundColor: 'white',
    padding: '15px',
    border: '1px solid #C6C9CD',
   minHeight: '22px',
    borderRadius: '5px'
  },

  label: {
    marginTop: '8px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#9aa0a8'
  },
  area: {
    marginTop: '8px',
    fontSize: '13px',
    border: '1px solid lightgray',
    borderRadius: '5px'
  },
  labelRoot: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#9aa0a8'
  },
  dialogTitle:{
    //backgroundColor: '#9aa0a8',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#9aa0a8',
    borderBottom: '2px solid #88929C'
  },
  dialogContent:{
   backgroundColor: 'white',
  },
  switch:{
    marginLeft: '10px',
   },
  dialogActions:{
    backgroundColor: 'white',
    borderTop:  '1px solid #9aa0a8',
    "& .MuiButton-textPrimary": {
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#9aa0a8',
    },
    "& .MuiButton-textSecondary": {
      color: '#9aa0a8',
      fontWeight: 'bold',
      border: '1px solid #9aa0a8'
    },
    areaResponse:{
      backgroundColor: '#F0F0F0'
    }

  },
}));

const tableIcons = {

  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} variant="outlined" {...props} ref={ref} />);

function TransitionRight(props) {
   return <Slide direction="left" {...props} />;
  }

function TableComponentAPI(props) {

  const classes = useStyles();

  var columns = [
    {title: "", width: 2, render: rowData => <Avatar maxInitials={1} size={40}  src={rowData === undefined ? "https://www.flaticon.es/svg/static/icons/svg/2165/2165004.svg" :rowData.image} name={rowData === undefined ? " " : rowData.apisource}   /> },
    {title: "Source", width: 10,defaultSort: 'asc', field: "apisource"},
    {title: "API", width: 20,field: "name"},
    {title: "Data path", width: 10,field: "keys3"},
    {title: "Type", width: 10,field: "filetype", 	initialEditValue: "text/csv"},
    {title: "File name", width: 10,field: "filename"},
    {title: "Scheduled", width: 5,field: "schedule", type: 'boolean'},
    {title: "Path", field: "path", hiddenByColumnsButton: true, hidden: true},
    {title: "Endpoint", field: "endpoint", hiddenByColumnsButton: true, hidden: true},
    {title: "Método", field: "method", hiddenByColumnsButton: true, hidden: true},
    {title: "Headers", field: "headers", hiddenByColumnsButton: true, hidden: true},
    {title: "Body", field: "body",hiddenByColumnsButton: true, hidden: true ,cellStyle: {minWidth: 350}, headerStyle: { minWidth:350}},
    {title: "Id", field: "id", editable: "never", hiddenByColumnsButton: true, hidden: true}
  ]

  const [data, setData] = useState([]); //table data
  const [response, setResponse] = useState();
  const [action, setAction] = useState([]);
  const [actionT, setActionT] = useState([]);
  const [actionE, setActionE] = useState([]);
  const [method, setMethod] = useState([]); //select
  const [authid, setAuthId] = useState([]); //select
  const [source, setSource] = useState([]); //select
  const [filetype, setFileType] = useState([]); //select
  const [partition, setPartition] = useState([]); //select
  const [openB, setOpenB] = React.useState(false); //progress
  const [schedule, setSchedule] = React.useState(false);
  const [async, setAsync] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [messageType, setMessageType] = React.useState();
  const [open, setOpen] = React.useState(false);
  //for error handling
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [openDialog, setOpenDialog] = React.useState(false);
  const [api, setAPI] = React.useState({});
  const [apiList, setAPIList] = React.useState([]);
  var actionsTable = []


  useEffect(() => {
      
     actionsTable.push( {
      icon:  () => <Cached/>,
      tooltip: 'Refresh',
      onClick: (event, rowData) => {
        handleRefresh(rowData)
      }
    })
    
    if (props.user['custom:author'] === true) {
       actionsTable.push({
        icon:  () => <Edit/>,
        tooltip: 'Edit',
        onClick: (event, rowData) => {
          new Promise((resolve) => {
          handleEdit(rowData, resolve)
         })
        }
      })
    }

    if (props.user['custom:author'] === true) {
      actionsTable.push({
        icon:  () => <AddBox/>,
        tooltip: 'Add',
        isFreeAction: true,
        onClick: (event, rowData) => {
            handleNew()
        }
      })
    }  
      
  setActionT(actionsTable)

  if (props.user['custom:author'] === true) {
  setActionE({
  onRowDelete: (oldData) =>
    new Promise((resolve) => {
      handleRowDelete(oldData, resolve)
    }),
  })
  }

  setOpenB(true) 
  fetch(Settings.backendPath + "/api/ribosomu/apis", {
    headers: {
      'Authorization': props.auth.access_token
    }})
  .then( res => res.json())
  .then( res => { 
    validateToken(res.status)
    setData(res)
    setAPIValue(res)
    setOpenB(false) 
   })
  },[]);


   const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


 //UPDATE  
  const handleRowUpdate = (newData, resolve) => {

    setOpenB(true);
    setOpenDialog(false);

    fetch(Settings.backendPath + '/API/ribosomu/apis/update', {
      method: 'POST',
      body: JSON.stringify(newData), 
      headers:{
        'Content-Type': 'application/json',
        'Authorization': props.auth.access_token
      }
    })
    .then( res => res.json())
    .then( res => { 
      validateToken(res.status)
      fetch(Settings.backendPath + "/api/ribosomu/apis", {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        validateToken(res.status)
        setData(res)
        setOpenB(false) 
       })
     })
    
  }

  //ADD
  const handleRowAdd = (newData, resolve) => {

    setOpenDialog(false);
    fetch(Settings.backendPath + '/API/ribosomu/apis/create', {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(newData), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json',
        'Authorization': props.auth.access_token
      }
    })
    .then( res => res.json())
    .then( res => { 
      fetch(Settings.backendPath + "/api/ribosomu/apis", {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        setData(res)
        setOpenB(false) 
       })
     })
  }

  //DELETE
  const handleRowDelete = (oldData, resolve) => {
    fetch(Settings.backendPath + '/API/ribosomu/apis/delete?id=' + oldData.id,{
      headers: {
        'Authorization': props.auth.access_token
      }})
    .then( res => res.json())
    .then( res => { 
      fetch(Settings.backendPath + "/api/ribosomu/apis", {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        setData(res)
        setOpenB(false) 
        resolve()
       })
     })
  }

    //REFRESH
    const handleRefresh = (data) => {

      if (data.async === true) {
        setOpen(true);
        setMessage('Data is loading. In a few minutes the process will finish.');
        setMessageType('success');
      } else {
        setOpenB(true) 
        setOpen(true);
        setMessage('Loading data ...');
        setMessageType('info');
      }

      fetch(Settings.backendPath + data.path + '?id=' + data.id , {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        validateToken(res.status)
        setOpenB(false) 
        setOpen(true)
        setMessage('Data loaded successfully');
        setMessageType('success');
       });

    }

    //NEW
    const handleNew = () => {
      setAction('new')
      var newAPI = {
        path : path
      }
      setMethod('');
      setAuthId('');
      setFileType('');
      setPartition('');
      setSource('')
      setSchedule(false)
      setAsync(false)
      setAPI(newAPI);
      setOpenDialog(true);
      }

    //EDIT
    const handleEdit = (data) => {
      setAction('edit')
      setAPI(data);
      setMethod(data.method);
      setAuthId(data.authid);
      setFileType(data.filetype);
      setPartition(data.partition);
      setSource(data.apisource)
      setSchedule(data.schedule)
      setAsync(data.async)
      setOpenDialog(true);
    }

    //SAVE
    const handleSave = (resolve) => {
      if (action === 'edit'){
        handleRowUpdate(api, resolve);
      } 
      else if (action === 'new'){
        handleRowAdd(api, resolve);
      }
    }

  //TRY
  const handleTry = () => {

    setOpenB(true) 
    fetch(Settings.backendPath + '/api/ribosomu/api/try', {
      headers: {
        'Authorization': props.auth.access_token,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(api)
    })
    .then( res => res.json())
    .then( res => { 
      validateToken(res.status)
      setResponse(res)
      setOpenB(false) 
     });
  }

    //CLOSE DIALOG
    const handleCloseDialog = (event, reason) => {
      setOpenDialog(false)
      fetch(Settings.backendPath + "/api/ribosomu/apis", {
        headers: {
          'Authorization': props.auth.access_token
        }})
      .then( res => res.json())
      .then( res => { 
        validateToken(res.status)
        setData(res)
        setResponse([])
       })
    };
  
    //INPUTS y AREAS
    const handleChanges = event => {
      console.log(event.target.value)
      var apiNew = api;
      apiNew[event.target.id != undefined? event.target.id: 'method'] = event.target.value
      setAPI(apiNew);
    };
      
     //SELECTS
    const handleChangeType = event => {
      setFileType(event.target.value)
      var apiNew = api;
      apiNew.filetype  = event.target.value
      setAPI(apiNew);
    };

    const handleChangeMethod = (event) => {
      setMethod(event.target.value)
      var apiNew = api;
      apiNew.method = event.target.value
      setAPI(apiNew);
    };

    const handleChangeAuthId = (event) => {
      setAuthId(event.target.value)
      var apiNew = api;
      apiNew.authid = event.target.value
      setAPI(apiNew);
    };

    const handleChangeSource = (event) => {
      setSource(event.target.value)
      var apiNew = api;
      apiNew.apisource  = event.target.value
      setAPI(apiNew);
    };

    const handlePartition = (event) => {
      setPartition(event.target.value)
      var apiNew = api;
      apiNew.partition  = event.target.value
      setAPI(apiNew);
    };

    const handleChangeSchedule = (event) => {
      setSchedule(event.target.checked);
      var apiNew = api;
      apiNew[event.target.name] = event.target.checked
      setAPI(apiNew);  
    };

    const handleChangeAsync = (event) => {
      setAsync(event.target.checked);
      var apiNew = api;
      apiNew[event.target.name] = event.target.checked
      setAPI(apiNew);  
    };

    function setAPIValue(data){
      
      var api = { id: null, value: ''}
      var apis = []
      apis.push(api)
      for (var i = 0; i < data.length; i++){      
        api = {
          id : data[i].id,
          value : data[i].name
        }
        apis.push(api)  
      }

      setAPIList(apis)
    }

  return (
    <div className={classes.root}>
       <Backdrop className={classes.backdrop} open={openB}>
         <CircularProgress color="inherit" />
       </Backdrop>  
       <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}
        anchorOrigin = {{ vertical: 'bottom', horizontal: 'left' }}
        TransitionComponent = {TransitionRight}
        >
        <Alert  severity={messageType}>
          {message}
        </Alert>
      </Snackbar>
       <Grid container spacing={2} className={classes.grid}>
       <Grid item xs={12}>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable 
              title=""
              columns={columns}
              actions={actionT}
              data={data}
              icons={tableIcons}
              options = {
                {pageSize: 10,
                addRowPosition: 'first',
                exportButton: true,
                rowStyle: { fontSize: 14},
                columnsButton: true,
                actionsCellStyle: {
                 
                }
              }
              }
              editable={actionE}
            />
            </Grid>
            </Grid>

       <Dialog  fullWidth = {true} maxWidth = {'lg'} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle className= {classes.dialogTitle} id="form-dialog-title">
        <Avatar maxInitials={1} size={40}  src={api === undefined ? "https://www.flaticon.es/svg/static/icons/svg/2165/2165004.svg" :api.image} name={api === undefined ? " " : api.apisource}   />
          {' ' + api.name }</DialogTitle>
        <DialogContent className= {classes.dialogContent}> 
          <DialogContentText>
          </DialogContentText>
          <Grid container>
          <Grid item xs={6}>
          <FormGroup className= {classes.dialog} column>
          <TextField
            autoFocus
            variant="outlined"
            id="name"
            label="API"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.name}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
          </FormGroup> 
         <FormGroup className= {classes.dialog} column> 
        <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="label4">Source</InputLabel>
        <Select 
          labelId="label4"
          label="Source"
          value={source}
          onChange={handleChangeSource}
        >
          <MenuItem value={''}>-</MenuItem>
          <MenuItem value={'BAMBOOHR'}>Bamboo HR</MenuItem>
          <MenuItem value={'GOOGLE'}>Google Drive</MenuItem>
          <MenuItem value={'HUBSPOT'}>Hubspot</MenuItem>
          <MenuItem value={'JIRA'}>Jira</MenuItem>
          <MenuItem value={'REST'}>Rest</MenuItem>
          <MenuItem value={'SQL'}>SQL</MenuItem>
          <MenuItem value={'RG'}>Resource Gurú</MenuItem>
        </Select>
        </FormControl>
         </FormGroup>
        <FormGroup className= {classes.dialog} column>
          <TextField 
            autoFocus
            variant="outlined"
            id="endpoint"
            label="API Endpoint"
            autoFocus={false}
            type="text"
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.endpoint}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
        </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
      <FormControl variant="outlined" className={classes.formControl}>  
       <InputLabel className= {classes.labelRoot} id="label2">Method</InputLabel>
        <Select 
          labelId="label2"
          value={method}
          label="Method"
          variant="outlined"
          onChange={handleChangeMethod}
        >
          <MenuItem value={'GET'}>GET</MenuItem>
          <MenuItem value={'POST'}>POST</MenuItem>
        </Select>
        </FormControl>
        </FormGroup> 
        <FormGroup className= {classes.dialog} column> 
      <FormControl variant="outlined" className={classes.formControl}>  
       <InputLabel className= {classes.labelRoot} id="labelAuth">Authentication API</InputLabel>
        <Select 
          labelId="labelAuth"
          value={authid}
          label="Authentication API"
          variant="outlined"
          onChange={handleChangeAuthId}> 
          {apiList.map(({id, value}) => (
            <MenuItem key={id} value={id}>{value}</MenuItem>
          ))}
        </Select>
        </FormControl>
        </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
        <TextField
            variant="outlined"
            id="headers"
            label="Headers"
            type="text"
            multiline = {true}
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.headers}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
      </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
          <TextField
            variant="outlined"
            id="body"
            label="Body"
            type="text"
            multiline = {true}
            autoFocus={false}
            onChange={handleChanges}
            defaultValue = {api.body}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
       </FormGroup> 
       <FormGroup className= {classes.dialog} column> 
         <TextField
            variant="outlined"
            id="for"
            label="Pagination (for)"
            type="number"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.for}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            
          />
        </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
         <TextField
            variant="outlined"
            id="pagination"
            label="Pagination endpoint"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.pagination}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            
          />
        </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
          <TextField

            variant="outlined"
            id="paginationkey"
            label="Pagination key"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.paginationkey}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            
          />
     </FormGroup>
     <FormGroup className= {classes.dialog} column> 
          <TextField
            variant="outlined"
            id="keydata"
            label="Response data path"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.keydata}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            
          />
       </FormGroup> 
        <FormGroup className= {classes.dialog} column>
          <TextField
            variant="outlined"
            id="keys3"
            label="Ribosomu file path"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.keys3}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            
          />
       </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
        <TextField
            variant="outlined"
            id="filename"
            label="File name"
            type="text"
            autoFocus={false}
            fullWidth
            onChange={handleChanges}
            defaultValue = {api.filename}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
          />
      </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
      <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="label1">File type</InputLabel>
        <Select 
          labelId="label1"
          id="filetype"
          variant="outlined"
          label="File type"
          value={filetype}
          onChange={handleChangeType}
        >
          <MenuItem value={'text/csv'}>CSV</MenuItem>
          <MenuItem value={'text/json'}>JSON</MenuItem>
        </Select>
        </FormControl>
        </FormGroup> 
      <FormGroup className= {classes.dialog} column> 
      <FormControl variant="outlined" className={classes.formControl}>  
        <InputLabel className= {classes.labelRoot} id="label3">Partition by</InputLabel>
        <Select 
          labelId="label3"
          id="partition"
          label="Partition by"
          value={filetype}
          variant="outlined"
          value={partition}
          onChange={handlePartition}
        >
          <MenuItem value={''}>-</MenuItem>
          <MenuItem value={'YEAR'}>Year</MenuItem>
          <MenuItem value={'MONTH'}>Month</MenuItem>
          <MenuItem value={'DAY'}>Day</MenuItem>
        </Select>
        </FormControl>
        </FormGroup>
        <FormGroup row>
        <FormControlLabel
          className= {classes.switch}
          control={<Switch checked={api.schedule} onChange={handleChangeSchedule} name="schedule" />}
          label="Schedule"
        />  
        <FormControlLabel
          className= {classes.switch}
          control={<Switch checked={api.async} onChange={handleChangeAsync} name="async" />}
          label="Async"
        />  
        </FormGroup>
        </Grid>
        <Grid item xs={6} className= {classes.areaResponse}>
        <FormGroup className= {classes.contentResponse} column> 
        <ReactJson src={response} enableClipboard={false}/>
          </FormGroup> 
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions className ={classes.dialogActions}> 
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={handleTry} color="secondary">
            Try
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TableComponentAPI;
