import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import queryString from 'query-string';
import { Settings } from '../settings.js';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = StyleSheet.create({
    iframe: {
        border: 0 
    },
    content: {
        height: '100%'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '60%'
    },
    imageContainer:{
        backgroundColor: 'white',
        height: '900px'
    },
    backdrop: {
        color: '#F4F4F4'
      }
});

class CallbackComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            auth : queryString.parse(this.props.location.hash),
            openB: true
        };
      }


    componentDidMount() {
        fetch(Settings.backendPath + '/API/ribosomu/user', {
            headers: {
              'Authorization': this.state.auth.access_token
            }})
        .then( res => res.json())
        .then( res => { 
           var user = res;
           fetch(Settings.backendPath + "/API/ribosomu/account?id=" + user['custom:account'], {
            headers: {
              'Authorization': this.state.auth.access_token
            }})
           .then(res => res.json())
           .then(res => {
            var account = res[0]; 
            this.props.action(this.state.auth,user, account); 
           }) 
        })   

   }

    render() {
             return (
                <div>
                <Backdrop className={css(styles.backdrop)} open={this.openB}>
                    <CircularProgress color="inherit" />
                </Backdrop>  
                </div> 
             );              
            }
    }


export default CallbackComponent;
