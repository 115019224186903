import React from 'react';
import { Column, Row } from 'simple-flexbox';
import { StyleSheet, css } from 'aphrodite';
import SidebarComponent from './components/sidebar/SidebarComponent';
import APIComponent from './components/content/APIComponent';
import HeaderComponent from './components/header/HeaderComponent';
import ContainerComponent from './components/content/ContainerComponent';
import CallbackComponent from './components/content/CallbackComponent';
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import './App.css';

const styles = StyleSheet.create({
    container: {
        height: '100%',
        minHeight: '100vh',
    },
    mainBlock: {
        backgroundColor: 'white',
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 12
    },

});

class App extends React.Component {

    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.setLogin = this.setLogin.bind(this);
        this.onselect = this.onselect.bind(this);
        this.state = {
          selectedItem: 'Home',
          is_logged: false,
          id: '',
          user: [],
          account: [],
          redirect: '/login',
          auth: []
        };
      }

    login(auth, userdb, accountdb) {

      this.setState({is_logged: true});
      this.setState({redirect: '/ribosomu'});
      this.setState({auth: auth});
      this.setState({user: userdb});
      this.setState({account: accountdb});
    }

    onselect(id) {
        this.setState({id: id}); 
    }

    componentDidMount() {
         window.addEventListener('resize', this.resize);      
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => this.forceUpdate();

    setLogin(){   
        if (this.state.is_logged === false) {
            setTimeout(function(){
                if (window.location.host === 'localhost:3000') {
                    window.location.href = 'https://ribosomu-dev.auth.eu-west-1.amazoncognito.com/login?client_id=p251v16qj45p3tj7v8v8apj5a&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=http://localhost:3000/callback'} else {
                    window.location.href = 'https://ribosomu-auth.auth.eu-west-1.amazoncognito.com/login?client_id=173g1ujfmm3tlsn464l76fptm5&response_type=token&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://ribosomu.strategicplatform.com/callback'}
            }, 300);
        }
    }
    
    render() {
        const  selectedItem = this.state.selectedItem;

            return (
               
                <BrowserRouter>
                    { ((window.location.pathname.includes("/callback") === false && this.state.is_logged === false) || this.state.is_logged === true) 
                    && <Redirect to= {this.state.redirect}/> 
                    }
                    <Switch>   
                    <Route
                        path="/ribosomu"
                        render={() => 
                        <Row className={css(styles.container)}> 
                        <SidebarComponent  user={this.state.user} account = {this.state.account} action={this.onselect} selectedItem={selectedItem} onChange={(selectedItem) => this.setState({ selectedItem })} /> 
                        <Column flexGrow={1} className={css(styles.mainBlock)}> 
                        <HeaderComponent title={selectedItem} user={this.state.user}/> 
                        <ContainerComponent auth = {this.state.auth} user={this.state.user} account = {this.state.account} action = {this.onselect} id = {this.state.id} item={selectedItem}/> 
                        </Column>  
                        </Row>
                        } />
                    <Route
                        path="/api/action/:action/:id"
                        component={APIComponent}/>
                    <Route  
                    path="/login"
                    render={this.setLogin}
                    />
                    <Route
                    path="/callback"
                    render={(props) => (
                        <CallbackComponent {...props} action={this.login} />
                      )}
                    />
                    />   
                    </Switch>
                </BrowserRouter>
            )
    }
}

export default App;